import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import CardInfo from '../../dashboard/components/CardInfo';
import {
  generateTimestampArray,
  toHHMMSS,
  toKM,
  toKnot,
  toMiles,
  toNM,
} from '../../../helpers/dateUtil';
import { ISummary } from '../../../types/summary.type';
import styled from 'styled-components';
import useCompany from '../../../hooks/useCompany';
import SummarySvc from '../../../services/SummarySvc';
import moment from 'moment';
import useFilter from '../../../hooks/useFilter';
import useAsset from '../../../hooks/useAsset';
import DeviceSvc from '../../../services/DeviceSvc';
import { IOperationDetail } from '../../../types/operationMode.type';
import SeriesSvc from '../../../services/SeriesSvc';
import { DeviceType } from '../../../types/device.type';
import { AssetType } from '../../../types/asset.type';
import { proconCustomCalculation } from '../../../helpers/asset';
import { getLabelFromKeyOperationMode } from '../../../helpers/text';

export interface table {
  description: string;
  value: string;
  unit: string;
}

const columns: ColumnsType<table> = [
  {
    title: 'Description',
    dataIndex: 'label1',
    key: 'label1',
  },
  {
    title: 'Value',
    dataIndex: 'value1',
    key: 'value1',
  },
  {
    title: 'Unit',
    dataIndex: 'unit1',
    key: 'unit1',
  },
  {
    title: 'Description',
    dataIndex: 'label2',
    key: 'label2',
  },
  {
    title: 'Value',
    dataIndex: 'value2',
    key: 'value2',
  },
  {
    title: 'Unit',
    dataIndex: 'unit2',
    key: 'unit2',
  },
];

interface IProps {
  data?: ISummary;
  isEMS: boolean;
  isSingleEngine: boolean;
  isHaveOperationMode: boolean | undefined;
  isThreeEngine: boolean | undefined;
  massId: string;
  isDailyReport?: boolean;
  compId?: string;
  summaryEventData?: any;
}

const unitDatas = [
  {
    value: 'KM',
    label: 'KM',
  },
  {
    value: 'NM',
    label: 'NM',
  },
  {
    value: 'MILES',
    label: 'MILES',
  },
];

const speedDatas = [
  {
    value: 'KNOT',
    label: 'KNOT',
  },
  {
    value: 'KM/H',
    label: 'KM/H',
  },
];

const ReportSummary = React.forwardRef<any, IProps>((props, ref) => {
  const { data, isDailyReport, isHaveOperationMode, isThreeEngine } = props;
  const [dataTables, setDataTables] = useState<any>();
  const [unit, setUnit] = useState<'KM' | 'NM' | 'MILES'>('KM');
  const [speed, setSpeed] = useState<'KNOT' | 'KM/H'>('KNOT');
  const { activeCompany } = useCompany();
  const { dataFilter } = useFilter();
  const { currentAsset } = useAsset();
  const [operationModeSummary, setOperationModeSummary] = useState<any>({});
  const dataFetchedRef = useRef(false);

  const [summaryEventData, setSummaryEventData] = useState<{
    portOn: number;
    portOff: number;
    centerOn: number;
    centerOff: number;
    starboardOn: number;
    starboardOff: number;
  }>({
    portOn: 0,
    portOff: 0,
    centerOn: 0,
    centerOff: 0,
    starboardOn: 0,
    starboardOff: 0,
  });

  function fetchSeriesData() {
    const gatewayDevice = currentAsset?.massDevice.find(
      (elm: DeviceType) => elm?.devcType === 'gateway'
    );
    const rpmDevice = currentAsset?.massDevice.find(
      (elm: DeviceType) => elm?.devcType === 'rpm'
    );

    const start = moment(dataFilter.range.startAt).valueOf() / 1000;
    const end = moment(dataFilter.range.endAt).valueOf() / 1000;

    Promise.all([
      SeriesSvc.getAggregatedDataByPeriod('gateway', start, end, {
        params: {
          aggregatedUnit: 'MINUTE',
          device: [gatewayDevice?.devcUniqueId],
        },
        headers: {
          'Cache-Control': 'max-age=3600',
        },
      }),
      SeriesSvc.getAggregatedDataByPeriod('rpm', start, end, {
        params: {
          aggregatedUnit: 'MINUTE',
          device: [rpmDevice?.devcUniqueId],
        },
        headers: {
          'Cache-Control': 'max-age=3600',
        },
      }),
    ])
      .then(([gatewayResponse, rpmResponse]) => {
        const gatewayData = gatewayResponse?.data?.data?.series;
        const rpmData = rpmResponse?.data?.data?.series;

        const portData = rpmData.filter((elm: any) => elm?.type === 'PORT');
        const ctrData = rpmData.filter((elm: any) => elm?.type === 'CENTER');
        const stbData = rpmData.filter((elm: any) => elm?.type === 'STARBOARD');

        const timestamps = generateTimestampArray(start, end, 60);

        const timePortData = timestamps
          .map((timestamp) => {
            const currPortData = portData.find(
              (elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp
            );
            const currGatewayData = gatewayData.find(
              (elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp
            );

            if (!currPortData && !currGatewayData) {
              return undefined;
            }

            if (currPortData?.rpm >= 300 && currGatewayData) {
              return {
                timestamp,
                engine: 'port',
                status: 'on',
                rpm: currPortData?.rpm,
              };
            }

            if (currGatewayData && (!currPortData || currPortData?.rpm < 300)) {
              return {
                timestamp,
                engine: 'port',
                status: 'off',
              };
            }

            return undefined;
          })
          .filter((elm) => elm !== undefined);

        const timeCtrData = timestamps
          .map((timestamp) => {
            const currCtrData = ctrData.find(
              (elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp
            );
            const currGatewayData = gatewayData.find(
              (elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp
            );

            if (currCtrData?.rpm >= 300 && currGatewayData) {
              return {
                timestamp,
                engine: 'center',
                status: currCtrData && currGatewayData ? 'on' : 'off',
                rpm: currCtrData?.rpm,
              };
            }

            if (currGatewayData && (!currCtrData || currCtrData?.rpm < 300)) {
              return {
                timestamp,
                engine: 'center',
                status: 'off',
              };
            }

            return undefined;
          })
          .filter((elm) => elm !== undefined);

        const timeStbData = timestamps
          .map((timestamp) => {
            const currStbData = stbData.find(
              (elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp
            );
            const currGatewayData = gatewayData.find(
              (elm: any) => Math.floor(elm?.timestamp / 60) * 60 === timestamp
            );

            if (currStbData?.rpm >= 300 && currGatewayData) {
              return {
                timestamp,
                engine: 'starboard',
                status: 'on',
                rpm: currStbData?.rpm,
              };
            }

            if (currGatewayData && (!currStbData || currStbData?.rpm < 300)) {
              return {
                timestamp,
                engine: 'starboard',
                status: 'off',
              };
            }

            return undefined;
          })
          .filter((elm) => elm !== undefined);

        /**
         * Generate Event Summary
         */

        const eventSummary = {
          portOn: timePortData.filter((elm) => elm?.status === 'on').length,
          portOff: timePortData.filter((elm) => elm?.status === 'off').length,
          centerOn: timeCtrData.filter((elm) => elm?.status === 'on').length,
          centerOff: timeCtrData.filter((elm) => elm?.status === 'off').length,
          starboardOn: timeStbData.filter((elm) => elm?.status === 'on').length,
          starboardOff: timeStbData.filter((elm) => elm?.status === 'off')
            .length,
        };

        setSummaryEventData(eventSummary);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (activeCompany?.compId === '44' || props.compId === '44') {
      if (!props.isDailyReport) {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchSeriesData();
      } else {
        setSummaryEventData(props.summaryEventData);
      }
    }
  }, []);

  useEffect(() => {
    if (data) {
      // Perhitungan custom untuk citra 02 di fuelcons dan avg flow
      let portTotalFuelCons = '0';
      let starboardTotalFuelCons = '0';
      if (props.massId === '62') {
        const proconDeviceData = proconCustomCalculation(
          data?.flowmeter?.data,
          dataFilter.interval
        );

        portTotalFuelCons = proconDeviceData.portTotalFuelCons;
        starboardTotalFuelCons = proconDeviceData.starboardTotalFuelCons;
      }

      let currDataTables;
      const constructDataTables = () => {
        if (data) {
          const rpm = data.rpm && data.rpm.summary;
          const fm =
            data.flowmeter &&
              data.flowmeter.summary &&
              data.flowmeter.data.length > 0
              ? data.flowmeter.summary
              : undefined;
          const ae = data.ae && data.ae.summary;
          console.log('fm', fm);

          const flowmeterPortFuelCons = data.flowmeter?.data?.reduce((acc, timeData) => {
            const value = timeData.PORT_FuelConsVolume ??
              timeData.PORT_FuelCons ??
              0;
            // console.log('Timestamp:', timeData);
            // console.log('PORT_FuelConsVolume:', timeData.PORT_FuelConsVolume);
            // console.log('PORT_FuelCons:', timeData.PORT_FuelCons);
            // console.log('Selected value:', value);
            // console.log('Running total:', acc + value);
            // console.log('------------------------');
            return acc + value;
          }, 0);
          // console.log('Final flowmeterPortFuelCons:', flowmeterPortFuelCons);

          const flowmeterStbFuelCons = data.flowmeter?.data?.reduce((acc, timeData) => {
            const value = timeData.STARBOARD_FuelConsVolume ??
              timeData.STARBOARD_FuelCons ??
              0;
            return acc + value;
          }, 0);

          const flowmeterMEFuelCons = (flowmeterPortFuelCons ?? 0) + (flowmeterStbFuelCons ?? 0);

          const AE1FuelCons = data.ae?.data
            ?.filter(timeData => {
              if (dataFilter.interval === 'HOUR') {
                return true
              } else {
                const startDate = moment(dataFilter.range.startAt).format("YYYY-MM-DD HH:mm:ss");
                const endDate = moment(dataFilter.range.endAt).format("YYYY-MM-DD HH:mm:ss");
                return timeData.AE1.datetime >= startDate && timeData.AE1.datetime <= endDate;
              }

            })
            ?.reduce((acc, timeData) => {
              const value = timeData.AE1.fuelConsumption ?? 0;
              return acc + value;
            }, 0);

          const AE2FuelCons = data.ae?.data
            ?.filter(timeData => {
              if (dataFilter.interval === 'HOUR') {
                return true
              } else {
                const startDate = moment(dataFilter.range.startAt).format("YYYY-MM-DD HH:mm:ss");
                const endDate = moment(dataFilter.range.endAt).format("YYYY-MM-DD HH:mm:ss");
                return timeData.AE2.datetime >= startDate && timeData.AE2.datetime <= endDate;
              }

            })
            ?.reduce((acc, timeData) => {
              const value = timeData.AE2.fuelConsumption ?? 0;
              return acc + value;
            }, 0);

          const AE3FuelCons = data.ae?.data
            ?.filter(timeData => {
              if (dataFilter.interval === 'HOUR') {
                return true
              } else {
                const startDate = moment(dataFilter.range.startAt).format("YYYY-MM-DD HH:mm:ss");
                const endDate = moment(dataFilter.range.endAt).format("YYYY-MM-DD HH:mm:ss");
                return timeData.AE3.datetime >= startDate && timeData.AE3.datetime <= endDate;
              }

            })
            ?.reduce((acc, timeData) => {
              const value = timeData.AE3.fuelConsumption ?? 0;
              return acc + value;
            }, 0);

          const AE4FuelCons = data.ae?.data
            // ?.filter(timeData => {
            //   if (!timeData.AE4) return false;
            //   const startDate = moment(dataFilter.range.startAt).format("YYYY-MM-DD HH:mm:ss");
            //   const endDate = moment(dataFilter.range.endAt).format("YYYY-MM-DD HH:mm:ss");
            //   return timeData.AE4?.datetime >= startDate && timeData.AE4?.datetime <= endDate;
            // })
            ?.reduce((acc, timeData) => {
              const value = timeData.AE4?.fuelConsumption ?? 0;
              return acc + value;
            }, 0);

          // const AE4FuelCons = data.ae?.data?.reduce((acc, timeData) => {
          //   const value = timeData.AE4 ? timeData.AE4.fuelConsumption : 0;
          //   return timeData.AE4 ? acc + value : acc;
          // }, 0);

          /**
           * NOTE:
           * - props.massId === '42' artinya ada perlakuan khusus untuk Meratus Medan 1
           */

          // Engine Speed & Fuel Cons
          // Single Engine
          const meEngineSpeed =
            rpm?.average.MAINENGINE ||
            rpm?.average.PORT ||
            rpm?.average.STARBOARD ||
            'N/A';
          const meRunningHour = rpm
            ? toHHMMSS(rpm.runningTime?.MAINENGINE || 0)
            : 'N/A';

          // PORT
          const portEngineSpeed = rpm ? rpm.average?.PORT?.toFixed(2) : 'N/A';

          const portFuelCons = isHaveOperationMode
            ? operationModeSummary?.portCons?.toFixed(2)
            : props.massId === '62' // pengkondisian untuk kapal citra 02 utk menghitung manual consumption
              ? portTotalFuelCons
              : flowmeterPortFuelCons
                ? flowmeterPortFuelCons.toFixed(2)
                : 'N/A';

          const portRunningHour =
            rpm && !props.isSingleEngine && rpm.runningTime.PORT
              ? toHHMMSS(rpm.runningTime.PORT)
              : 'N/A';

          // STARBOARD
          const stbEngineSpeed = rpm
            ? rpm.average?.STARBOARD?.toFixed(2)
            : 'N/A';

          const stbFuelCons = isHaveOperationMode
            ? operationModeSummary?.stbCons?.toFixed(2)
            : props.massId === '62' // pengkondisian untuk kapal citra 02 utk menghitung manual consumption
              ? starboardTotalFuelCons
              : flowmeterStbFuelCons
                ? flowmeterStbFuelCons.toFixed(2)
                : 'N/A';

          const stbRunningHour =
            rpm && !props.isSingleEngine
              ? toHHMMSS(rpm.runningTime.STARBOARD)
              : 'N/A';

          // STARBOARD
          const ctrEngineSpeed =
            rpm && rpm.average?.CENTER
              ? rpm.average?.CENTER?.toFixed(2)
              : 'N/A';
          const ctrRunningHour =
            rpm && !props.isSingleEngine
              ? toHHMMSS(rpm.runningTime?.CENTER)
              : 'N/A';

          // AE Running Hour & Fuel Cons
          const ae1RunningHour = ae ? toHHMMSS(ae.runningSeconds.AE1) : 'N/A';
          const ae1FuelCons =
            ae && ae.fuelConsumption.AE1
              ? (AE1FuelCons ?? 0).toFixed(2)
              : 'N/A';
          const ae2RunningHour = ae ? toHHMMSS(ae.runningSeconds.AE2) : 'N/A';
          const ae2FuelCons =
            ae && ae.fuelConsumption.AE2
              ? (AE2FuelCons ?? 0).toFixed(2)
              : 'N/A';
          const ae3RunningHour = ae ? toHHMMSS(ae.runningSeconds.AE3) : 'N/A';
          const ae3FuelCons =
            ae && ae.fuelConsumption.AE3
              ? (AE3FuelCons ?? 0).toFixed(2)
              : 'N/A';
          const ae4RunningHour = ae ? toHHMMSS(ae.runningSeconds.AE4) : 'N/A';
          const ae4FuelCons =
            ae && ae.fuelConsumption.AE4
              ? (AE4FuelCons ?? 0).toFixed(2)
              : 'N/A';

          // Total Fuel Used
          // const engineFuelUsed =
          //   props.massId === '62'
          //     ? parseFloat(portTotalFuelCons) +
          //     parseFloat(starboardTotalFuelCons)
          //     : fm
          //       ? fm.meEngineCons
          //       : 0;

          const engineFuelUsed =
            props.massId === '62'
              ? parseFloat(portTotalFuelCons) +
              parseFloat(starboardTotalFuelCons)
              : flowmeterMEFuelCons

          let aeFuelUsed: number;

          // fuel used untuk ae turaco 316 menggunakan data aeEngineCons
          if (props.massId === '43') {
            aeFuelUsed = fm?.aeEngineCons ?? 0;
          } else {
            aeFuelUsed =
              // (ae?.fuelConsumption.AE1 || 0) +
              // (ae?.fuelConsumption.AE2 || 0) +
              // (ae?.fuelConsumption.AE3 || 0) +
              // (ae?.fuelConsumption.AE4 || 0);
              (AE1FuelCons || 0) + (AE2FuelCons || 0) + (AE3FuelCons || 0) + (AE4FuelCons || 0);
          }

          const totalFuelUsed = (
            engineFuelUsed + (props.massId === '42' ? 0 : aeFuelUsed)
          ).toFixed(2);

          if (props.isSingleEngine) {
            currDataTables = [
              {
                label1: 'ME Engine Speed',
                value1:
                  typeof meEngineSpeed === 'number'
                    ? meEngineSpeed.toFixed(2)
                    : meEngineSpeed,
                unit1: 'RPM',
                label2: 'ME Total Fuel Used',
                value2: engineFuelUsed.toFixed(2),
                unit2: 'Liter',
              },
              {
                label1: 'ME Engine Running Hour',
                value1: meRunningHour,
                unit1: 'Hour',
                label2: '',
                value2: '',
                unit2: '',
              },
              {
                label1: 'Port AE1 Running Hour',
                value1: ae1RunningHour,
                unit1: 'Hour',
                label2: '',
                value2: '',
                unit2: '',
              },
              {
                label1: 'Center AE2 Running Hour',
                value1: ae2RunningHour,
                unit1: 'Hour',
                label2: 'Total AE Fuel Used',
                value2: props.massId !== '42' ? aeFuelUsed.toFixed(2) : 'N/A',
                unit2: 'Liter',
              },
              {
                label1: 'Starboard AE3 Running Hour',
                value1: ae3RunningHour,
                unit1: 'Hour',
                label2: 'Total Fuel Used',
                value2: totalFuelUsed,
                unit2: 'Liter',
              },
            ];
          } else {
            if (activeCompany?.compId === '39' || props?.compId === '39') {
              currDataTables = [
                {
                  label1: 'Port ME Engine Speed',
                  value1: portEngineSpeed,
                  unit1: 'RPM',
                  label2: 'Port ME Total Fuel Used',
                  value2: portFuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'Center ME Engine Speed',
                  value1: ctrEngineSpeed,
                  unit1: 'RPM',
                  label2: 'Center ME Total Fuel Used',
                  value2: 'N/A',
                  unit2: 'Liter',
                },
                {
                  label1: 'Starboard ME Engine Speed',
                  value1: stbEngineSpeed,
                  unit1: 'RPM',
                  label2: 'Starboard ME Total Fuel Used',
                  value2: stbFuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'Port ME Running Hour',
                  value1: portRunningHour,
                  unit1: 'Hour',
                  label2: 'AE 1 Total Fuel Used',
                  value2: ae1FuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'Center ME Running Hour',
                  value1: ctrEngineSpeed,
                  unit1: 'Hour',
                  label2: 'AE 2 Total Fuel Used',
                  value2: ae2FuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'Starboard ME Running Hour',
                  value1: stbRunningHour,
                  unit1: 'Hour',
                  label2: 'AE 3 Total Fuel Used',
                  value2: ae3FuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'AE 1 Running Hour',
                  value1: ae1RunningHour,
                  unit1: 'Hour',

                  label2: 'AE 4 Total Fuel Used',
                  value2: ae4FuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'AE 2 Running Hour',
                  value1: ae2RunningHour,
                  unit1: 'Hour',

                  label2: 'ME Total Fuel Used',
                  value2: engineFuelUsed.toFixed(2),
                  unit2: 'Liter',
                },
                {
                  label1: 'AE 3 Running Hour',
                  value1: ae3RunningHour,
                  unit1: 'Hour',
                  label2: 'AE Total Fuel Used',
                  value2: aeFuelUsed.toFixed(2),
                  unit2: 'Liter',
                },
                {
                  label1: 'AE 4 Running Hour',
                  value1: ae4RunningHour,
                  unit1: 'Hour',

                  label2: 'Total Fuel Used',
                  value2: totalFuelUsed,
                  unit2: 'Liter',
                },
              ];
            } else if (
              activeCompany?.compId === '44' ||
              props?.compId === '44'
            ) {
              currDataTables = [
                {
                  label1: 'Port ME Engine Speed',
                  value1: portEngineSpeed,
                  unit1: 'RPM',
                  label2: 'AE 1 Running Hour',
                  value2: ae1RunningHour,
                  unit2: 'Hour',
                },
                {
                  label1: 'Center ME Engine Speed',
                  value1: ctrEngineSpeed,
                  unit1: 'RPM',
                  label2: 'AE 2 Running Hour',
                  value2: ae2RunningHour,
                  unit2: 'Hour',
                },
                {
                  label1: 'Starboard ME Engine Speed',
                  value1: stbEngineSpeed,
                  unit1: 'RPM',
                  label2: 'AE 3 Running Hour',
                  value2: ae3RunningHour,
                  unit2: 'Hour',
                },
                {
                  label1: 'Port Total Engine ON',
                  value1: toHHMMSS(summaryEventData?.portOn * 60),
                  unit1: 'Hour',
                  label2: 'Port Total Engine OFF',
                  value2: toHHMMSS(summaryEventData?.portOff * 60),
                  unit2: 'Hour',
                },
                {
                  label1: 'Center Total Engine ON',
                  value1: toHHMMSS(summaryEventData?.centerOn * 60),
                  unit1: 'Hour',
                  label2: 'Center Total Engine OFF',
                  value2: toHHMMSS(summaryEventData?.centerOff * 60),
                  unit2: 'Hour',
                },
                {
                  label1: 'Starboard Total Engine ON',
                  value1: toHHMMSS(summaryEventData?.starboardOn * 60),
                  unit1: 'Hour',
                  label2: 'Starboard Total Engine OFF',
                  value2: toHHMMSS(summaryEventData?.starboardOff * 60),
                  unit2: 'Hour',
                },
              ];
            } else {
              currDataTables = [
                {
                  label1: 'Port ME Engine Speed',
                  value1: portEngineSpeed,
                  unit1: 'RPM',
                  label2: 'Port ME Total Fuel Used',
                  value2: portFuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'Center ME Engine Speed',
                  value1: ctrEngineSpeed,
                  unit1: 'RPM',
                  label2: 'Center ME Total Fuel Used',
                  value2: 'N/A',
                  unit2: 'Liter',
                },
                {
                  label1: 'Starboard ME Engine Speed',
                  value1: stbEngineSpeed,
                  unit1: 'RPM',
                  label2: 'Starboard ME Total Fuel Used',
                  value2: stbFuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'Port ME Running Hour',
                  value1: portRunningHour,
                  unit1: 'Hour',
                  label2: 'AE 1 Total Fuel Used',
                  value2: ae1FuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'Center ME Running Hour',
                  value1: ctrRunningHour,
                  unit1: 'Hour',
                  label2: 'AE 2 Total Fuel Used',
                  value2: ae2FuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'Starboard ME Running Hour',
                  value1: stbRunningHour,
                  unit1: 'Hour',
                  label2: 'AE 3 Total Fuel Used',
                  value2: ae3FuelCons,
                  unit2: 'Liter',
                },
                {
                  label1: 'AE 1 Running Hour',
                  value1: ae1RunningHour,
                  unit1: 'Hour',
                  label2: 'ME Total Fuel Used',
                  value2: engineFuelUsed.toFixed(2),
                  unit2: 'Liter',
                },
                {
                  label1: 'AE 2 Running Hour',
                  value1: ae2RunningHour,
                  unit1: 'Hour',
                  label2: 'AE Total Fuel Used',
                  value2: aeFuelUsed.toFixed(2),
                  unit2: 'Liter',
                },
                {
                  label1: 'AE 3 Running Hour',
                  value1: ae3RunningHour,
                  unit1: 'Hour',
                  label2: 'Total Fuel Used',
                  value2: totalFuelUsed,
                  unit2: 'Liter',
                },
              ];
            }
          }
        }
      };

      constructDataTables();
      setDataTables(currDataTables);
    }
  }, [
    data,
    props.massId,
    props.isSingleEngine,
    operationModeSummary,
    summaryEventData,
  ]);

  const totalAeRunningTime = useMemo(() => {
    let result = 0;
    if (data && data.ae) {
      result = Object.values(data.ae.summary.runningSeconds).reduce(
        (acc, curr) => acc + curr,
        0
      );
    }

    return result;
  }, [data]);

  const totalDistance = useMemo(() => {
    let result = 'N/A';
    if (data && data.gps) {
      switch (unit) {
        case 'KM':
          result = toKM(data.gps.summary.distance);
          break;
        case 'MILES':
          result = toMiles(data.gps.summary.distance);
          break;
        case 'NM':
          result = toNM(data.gps.summary.distance);
          break;

        default:
          break;
      }
    }

    return result;
  }, [data, unit]);

  const averageSpeed = useMemo(() => {
    let result = 'N/A';
    if (data && data.gps && data.gps.summary) {
      if (speed === 'KNOT') {
        result = toKnot(data.gps.summary.averageSpeed);
      } else {
        result = data.gps.summary.averageSpeed.toFixed(2);
      }

      let avgSpeed = parseFloat(result);
      if (avgSpeed < 0) {
        result = String(-avgSpeed);
      }
    }

    return result;
  }, [data, speed]);

  const totalCruise = useMemo(() => {
    let result = 'N/A';
    if (data && data.gps && data.gps.summary) {
      switch (unit) {
        case 'KM':
          result = toKM(data.gps.summary.totalDistance);
          break;
        case 'MILES':
          result = toMiles(data.gps.summary.totalDistance);
          break;
        case 'NM':
          result = toNM(data.gps.summary.totalDistance);
          break;

        default:
          break;
      }
    }
    return result;
  }, [data, unit]);

  const handleChangeUnit = (value: any) => {
    setUnit(value);
  };

  const handleChangeSpeed = (value: any) => {
    setSpeed(value);
  };

  const _renderOperationModeBody = (
    opsModeTemplate: any[],
    operationModeSummary: any
  ) => {
    let containers = opsModeTemplate
      .map((item) => item.mrodName)
      .reduce((acc: any, label: any) => {
        acc[label] = { maxRpm: 0, value: 0, runningSeconds: 0 };
        return acc;
      }, {});

    if (!operationModeSummary) {
      return (
        <tbody>
          {Object.keys(containers).map((item: any, idx: number) => (
            <tr key={idx}>
              <td>{item}</td>
              <td>00:00:00</td>
              <td>0</td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            <td>00:00:00</td>
            <td>0</td>
          </tr>
        </tbody>
      );
    } else {
      const summary = operationModeSummary.total.mode;
      Object.keys(summary).forEach((key) => {
        const label = getLabelFromKeyOperationMode(key);

        if (label) {
          containers[label].maxRpm += parseInt(summary[key].maxRpm);
          containers[label].value += summary[key].value;
          containers[label].runningSeconds += summary[key].runningSeconds;
        }
      });

      return (
        <tbody>
          {Object.keys(containers).map((item: any, idx: number) => (
            <tr key={idx}>
              <td>{item}</td>
              <td>{toHHMMSS(containers[item].runningSeconds)}</td>
              <td>{containers[item].value.toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            <td>{toHHMMSS(operationModeSummary.total.runningSeconds)}</td>
            <td>{operationModeSummary.total.value.toFixed(2)}</td>
          </tr>
        </tbody>
      );
    }
  };

  return (
    <>
      {!isDailyReport && (
        <SummaryInfo style={{ marginBottom: 20 }}>
          <CardInfo
            style={{ width: '100%' }}
            label='Total Cruise'
            value={totalCruise}
            unit={unit}
            onChange={handleChangeUnit}
            dropdown={!isDailyReport}
            options={unitDatas}
          />
          <CardInfo
            style={{ width: '100%' }}
            label='Average Speed'
            value={averageSpeed}
            unit={speed}
            onChange={handleChangeSpeed}
            dropdown={!isDailyReport}
            options={speedDatas}
          />
          <CardInfo
            style={{ width: '100%' }}
            label='Distance'
            value={totalDistance}
            unit={unit}
          />
          <CardInfo
            style={{ width: '100%' }}
            label='Total AE Running Time'
            value={toHHMMSS(totalAeRunningTime)}
            unit='Hour'
          />
        </SummaryInfo>
      )}
      {dataTables && dataTables.length > 0 && (
        <table
          className={
            isDailyReport ? 'custom-table-daily-report-summary' : 'custom-table'
          }
          id='summaryTable'
        >
          <thead>
            <tr>
              {columns.map((item, idx) => (
                <th key={idx}>{item.title?.toString()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataTables.map((row: any) => (
              <tr key={row.key}>
                {Object.values(row).map((value: any, idx: number) => (
                  <td
                    key={`${idx}-${value}`}
                    style={{ color: value === 'N/A' ? 'gray' : 'black' }}
                  >
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* <Table
        id='summaryTable'
        ref={ref}
        size='small'
        columns={columns}
        dataSource={dataTables}
        pagination={false}
      /> */}
      {(props.compId === '9' ||
        props.compId === '41' ||
        activeCompany?.compGrcpId === '19') &&
        !isDailyReport &&
        (currentAsset?.opsModeTemplate ? (
          <div style={{ marginTop: '20px', paddingBottom: '30px' }}>
            <table id='summaryTable' className='custom-table'>
              <thead>
                <tr>
                  <th>Operating Mode</th>
                  <th>Running Time</th>
                  <th>Fuel Cons</th>
                </tr>
              </thead>

              {_renderOperationModeBody(
                currentAsset?.opsModeTemplate,
                currentAsset.operationModeSummary
              )}

              {/* <tbody>
                {currentAsset?.opsModeTemplate &&
                  currentAsset?.opsModeTemplate.map((elm, idx: number) => {
                    const foundData = currentAsset.operationModeSummary?.total
                      ?.mode
                      ? currentAsset.operationModeSummary?.total?.mode[
                          elm.mrodName
                        ]
                      : undefined;

                    if (foundData) {
                      return (
                        <tr key={idx}>
                          <td>{elm.mrodName}</td>
                          <td>{toHHMMSS(foundData.runningSeconds)}</td>
                          <td>{foundData.value.toFixed(2)}</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={idx}>
                          <td>{elm.mrodName}</td>
                          <td>{toHHMMSS(0)}</td>
                          <td>{0}</td>
                        </tr>
                      );
                    }
                  })}
                <tr key={'total'}>
                  <td>Total</td>
                  <td>
                    {toHHMMSS(
                      currentAsset.operationModeSummary?.total?.runningSeconds
                        ? currentAsset.operationModeSummary?.total
                            ?.runningSeconds
                        : 0
                    )}
                  </td>
                  <td>
                    {currentAsset.operationModeSummary?.total?.value &&
                      currentAsset.operationModeSummary?.total?.value.toFixed(
                        2
                      )}
                  </td>
                </tr>
              </tbody> */}
            </table>
          </div>
        ) : (
          <p style={{ textAlign: 'center', marginTop: '20px' }}>
            No Operation Mode Data
          </p>
        ))}
    </>
  );
});

const SummaryInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;

export default ReportSummary;
